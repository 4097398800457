import { useState, useEffect, useRef } from 'react'
import { Box, Card, CardContent, Typography, Avatar, Rating, Container, Button, useMediaQuery, useTheme } from '@mui/material'
import './style.css';

const testimonials = [
  {
    name: 'Vilson Dieter',
    position: 'Administrador',
    company: '',
    quote: 'Fiz negócio com eles na compra de uma unidade do empreendimento UP Side da Rôgga. Fui muito bem atendido antes, durante e também no pós venda. Eles são fantásticos e respondem prontamente as dúvidas e facilitaram em todo o processo.'
  },
  {
    name: 'Josi e Alan',
    position: 'Administração Financeira',
    company: 'Engenheiro Civil',
    quote: 'Chegamos à Vext através da Lívia, uma corretora muito profissional que entendeu o que procurávamos e nos ofereceu opções que de fato encaixaram com nossas expectativas.'
  },
  {
    name: 'Tamara Rebelo',
    position: 'Cliente Vext',
    company: '',
    quote: 'O corretor nos atendeu de forma excelente, nos orientando cuidadosamente para fazer a melhor escolha. Nos sentimos confiantes durante todo o processo.'
  },
  {
    name: 'Paulo Zerbinato',
    position: 'Empresário',
    company: '',
    quote: 'Gostaria de expressar meu sincero agradecimento aos corretores/amigos Maycon e João, da Vext.'
  },
  {
    name: 'Alexsandro de Oliveira',
    position: 'Empresário',
    company: '',
    quote: 'Iniciei meu contato com a VEXT através da indicação de um grande amigo que também me referendou a assessoria do Diogo. Logo que iniciamos as negociações já era nítido que se tratava de um profissional com princípios ilibados e que não estava meramente preocupado com a questão "fechamento", e sim entender se o que estava sendo apresentado realmente fazia sentido dentro do que eu buscava. Parabéns à VEXT pelo profissional íntegro que faz parte de sua equipe e ao Diogo pelo sucesso alcançado, atingindo o seleto grupo do VEXT CLUB… que Deus continue te abençoando grandemente!!!'
  },
  {
    name: 'Edyval de Carvalho',
    position: 'Administrador',
    company: '',
    quote: 'A relação com a empresa Vext foi bastante tranquila, me senti muito seguro e confortável com os diálogos, informações e assessoria no processo. Além disso, a preocupação da assessora Liege em entender o que estávamos buscando foi fundamental no negócio. Fomos ganhando confiança tirando nossas dúvidas de forma simples e rápida. Fundamental para fecharmos o negócio. Nos sentimos "em casa".'
  },
  {
    name: 'Cristiane Assunção',
    position: 'Empresária',
    company: '',
    quote: 'Bom, sou suspeita em falar porque já conhecemos a Thaise há um bom tempo!! Estávamos sonhando alto e ela foi extremamente específica em encontrar o apartamento com todas as qualificações que a minha família precisava. Quando fizemos a escolha do apartamento, o nome da Vext foi essencial para conseguir extrair a melhor negociação com a construtora em questão. Estamos muito felizes com todo atendimento da Thaise e da equipe Vext.'
  }
];

const TRUNCATE_LENGTH = 200; // Ajuste o tamanho conforme necessário

// Componente para cada "card" de depoimento
const TestimonialCard = ({ name, position, company, quote }: any) => {
  // Estado para controlar se o depoimento está expandido ou não
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);

  const initials = name ? name.split(' ').map((n: string) => n[0]).join('') : '?';

  // Verifica se o depoimento é longo
  const isLong = quote.length > TRUNCATE_LENGTH;
  // Se não estiver expandido e for longo, exibe truncado
  const displayedQuote = !expanded && isLong ? quote.substring(0, TRUNCATE_LENGTH) + '...' : quote;

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', bgcolor: 'white', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '4px' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Rating name="read-only" value={5} readOnly />
        <Typography variant="body1" sx={{ mt: 2, color: '#333', fontSize: '16px' }}>
          {displayedQuote}
          {isLong && !expanded && (
            <Button size="small" onClick={toggleExpanded} sx={{ textTransform: 'none', p: 0, ml: 1 }}>
              Ver mais
            </Button>
          )}
          {isLong && expanded && (
            <Button size="small" onClick={toggleExpanded} sx={{ textTransform: 'none', p: 0, ml: 1 }}>
              Ver menos
            </Button>
          )}
        </Typography>
      </CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Avatar sx={{ bgcolor: '#AAAAAA', color: '#fff', mr: 2 }}>{initials}</Avatar>
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{name || 'Anonymous'}</Typography>
          <Typography variant="body2" sx={{ color: '#777' }}>
            {position}{company ? `, ${company}` : ''}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default function Testimonials() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerPage = isMobile ? 1 : 3;
  const totalItems = testimonials.length;

  // Para o efeito infinito, clonamos os últimos e primeiros "itemsPerPage" depoimentos
  const clonesBefore = testimonials.slice(-itemsPerPage);
  const clonesAfter = testimonials.slice(0, itemsPerPage);
  const extendedTestimonials = [...clonesBefore, ...testimonials, ...clonesAfter];

  // O índice real inicial deve ser igual a itemsPerPage (pois os primeiros itens são clones)
  const [currentIndex, setCurrentIndex] = useState(itemsPerPage);
  const [translateX, setTranslateX] = useState(-itemsPerPage * (100 / itemsPerPage));

  // Se o número de itens por página mudar, reiniciamos o índice e a translação
  useEffect(() => {
    setCurrentIndex(itemsPerPage);
    setTranslateX(-itemsPerPage * (100 / itemsPerPage));
  }, [itemsPerPage]);

  // Atualiza o translateX sempre que o índice atual mudar
  useEffect(() => {
    setTranslateX(-currentIndex * (100 / itemsPerPage));
  }, [currentIndex, itemsPerPage]);

  // Referência para controlar o intervalo de autoplay
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Função para iniciar o autoplay
  const startAutoPlay = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setCurrentIndex((prev) => prev + 1);
      }, 5000);
    }
  };

  // Função para parar o autoplay
  const stopAutoPlay = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  // Ao montar o componente, iniciamos o autoplay
  useEffect(() => {
    startAutoPlay();
    return () => stopAutoPlay(); // limpar ao desmontar
  }, []);

  // Ajusta o índice para efeito de loop infinito
  useEffect(() => {
    // Se estiver nos clones do início, pula para o final real
    if (currentIndex < itemsPerPage) {
      setTimeout(() => {
        setCurrentIndex(currentIndex + totalItems);
        setTranslateX(-(currentIndex + totalItems) * (100 / itemsPerPage));
      }, 0);
    }
    // Se estiver nos clones do final, pula para o início real
    else if (currentIndex >= itemsPerPage + totalItems) {
      setTimeout(() => {
        setCurrentIndex(currentIndex - totalItems);
        setTranslateX(-(currentIndex - totalItems) * (100 / itemsPerPage));
      }, 0);
    }
  }, [currentIndex, totalItems, itemsPerPage]);

  // Estados e refs para manipulação de toque (drag)
  const [isDragging, setIsDragging] = useState(false);
  const startXRef = useRef(0);
  const currentTranslateXRef = useRef(translateX);
  const animationRef = useRef<number>(0);

  // Eventos de touch
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
    startXRef.current = e.touches[0].clientX;
    currentTranslateXRef.current = translateX;
    cancelAnimationFrame(animationRef.current);
    stopAutoPlay(); // Pausa autoplay ao iniciar arraste
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startXRef.current;
    const percentageDeltaX = (deltaX / window.innerWidth) * 100 * itemsPerPage;
    setTranslateX(currentTranslateXRef.current + (percentageDeltaX / itemsPerPage));
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    const movedBy = translateX - currentTranslateXRef.current;
    const threshold = 20 / itemsPerPage; // Ajuste conforme necessário
    if (movedBy < -threshold) {
      setCurrentIndex((prev) => prev + 1);
    } else if (movedBy > threshold) {
      setCurrentIndex((prev) => prev - 1);
    } else {
      setTranslateX(-currentIndex * (100 / itemsPerPage));
    }
    startAutoPlay(); // Retoma autoplay após arraste
  };

  // Eventos de mouse para pausar/resumir o autoplay
  const handleMouseEnter = () => stopAutoPlay();
  const handleMouseLeave = () => startAutoPlay();

  return (
    <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
      <Container sx={{ maxWidth: '1000px !important', px: { xs: 2, sm: 4, md: 6 } }}>
        <Typography className="section-title">
          Depoimentos
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph sx={{ fontSize: '18px', color: '#666' }}>
          Experiências que Inspiram e Conquistas Compartilhadas
        </Typography>

        <Box
          component="div"
          sx={{
            position: 'relative',
            overflow: 'hidden',
            my: 4,
            mx: { xs: -2, sm: -4, md: -6 }
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Box
            sx={{
              display: 'flex',
              transition: isDragging ? 'none' : 'transform 1s ease-in-out',
              transform: `translateX(${translateX}%)`,
              py: 4
            }}
          >
            {extendedTestimonials.map((testimonial, index) => (
              <Box
                key={index}
                sx={{
                  flexShrink: 0,
                  width: `${100 / itemsPerPage}%`,
                  px: 2,
                  py: 4,
                }}
              >
                <TestimonialCard {...testimonial} />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Indicadores (bolinhas) */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          {[...Array(totalItems)].map((_, index) => (
            <Button
              key={index}
              variant={
                (currentIndex - itemsPerPage) === index
                  ? 'contained'
                  : 'outlined'
              }
              size="small"
              sx={{
                minWidth: 'auto',
                width: 12,
                height: 12,
                p: 0,
                mx: 0.5,
                borderRadius: '50%',
                borderColor: (currentIndex - itemsPerPage) === index ? '#C7FD06' : 'grey.400',
                bgcolor: (currentIndex - itemsPerPage) === index ? '#C7FD06' : '#8D8D8D',
                '&:hover': {
                  bgcolor: (currentIndex - itemsPerPage) === index ? '#B3E305' : '#8D8D8D',
                  borderColor: (currentIndex - itemsPerPage) === index ? '#B3E305' : 'grey.600',
                },
              }}
              onClick={() => setCurrentIndex(index + itemsPerPage)}
              aria-label={`Ir para o depoimento ${index + 1}`}
            >
              <Box component="span" sx={{ display: 'none' }}>
                Depoimento {index + 1}
              </Box>
            </Button>
          ))}
        </Box>

        <Box sx={{ textAlign: 'center', my: 6 }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: '#C7FD06',
              color: 'black',
              '&:hover': { bgcolor: '#B3E305' },
            }}
            onClick={() => window.open('https://maps.app.goo.gl/wKPr5idcDfpnqbcG8', '_blank')}
          >
            Avaliar agora
          </Button>
        </Box>
      </Container>
    </Box>
  );
}